import mixpanel from "mixpanel-browser";

declare global {
	interface Window {
		webengage: any;
		Razorpay: any;
		mixpanel: any;
	}
}

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN ?? "none";
const WEBENGAGE_SECRET = process.env.REACT_APP_WEBENGAGE_SCECRET ?? "none";

const env_check = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development";

if (MIXPANEL_TOKEN === "none" && env_check) {
	// eslint-disable-next-line no-console
	console.warn("🚀 ~ Mixpanel token is not provided");
}

if (!window.webengage && WEBENGAGE_SECRET === "none" && env_check) {
	// eslint-disable-next-line no-console
	console.warn("🚀 ~ Webengage has not been setup correctly");
}

window.mixpanel = mixpanel;
mixpanel.init(MIXPANEL_TOKEN, { ignore_dnt: true });
window.webengage.init(WEBENGAGE_SECRET);

const actions = {
	identify: (id: string) => {
		if (env_check) {
			window.webengage.user.login(id);
			mixpanel.identify(id);
		}
	},
	alias: (id: string) => {
		if (env_check) {
			mixpanel.alias(id);
		}
	},
	track: (name: string, props: any = {}) => {
		if (env_check) {
			mixpanel.track(name, props);
			window.webengage.track(name, props);
		}
	},
	trackTiming: (name: string) => {
		if (env_check) {
			mixpanel.time_event(name);
		}
	},
	trackOrder: (name: string, order: VisaOrder, props: any = {}) => {
		props = {
			...props,
			country_symbol: order.country.symbol,
			visa_type: order.visa_type.visa_type,
			travel_start_date: order.travel_start_date,
			travel_end_date: order.travel_end_date,
			number_of_travellers: order.no_of_travelers,
			order_id: order.order_id,
			current_timeline_status: order.timeline_status
		};
		if (env_check) {
			mixpanel.track(name, props);
			window.webengage.track(name, props);
		}
	},
	reset: () => {
		mixpanel.reset();
		window.webengage.user.logout();
	},
	people: {
		set: (props: any) => {
			if (env_check) mixpanel.people.set(props);
		},
		set_once: (props: any) => {
			if (env_check) mixpanel.people.set_once(props);
		}
	},
	webengage: {
		set: (name: string, value: any) => {
			window.webengage.user.setAttribute(name, value);
		}
	}
};

export const Mixpanel = actions;

export const MIXPANEL_EVENTS = {
	// Login & sign up events
	LOGIN_FAILED: "LOGIN_FAILED",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	SIGN_UP_EMAIL_STEP_COMPLETED: "SIGN_UP_EMAIL_STEP_COMPLETED",
	SIGN_UP_ALREADY_USER: "SIGN_UP_ALREADY_USER",
	SIGN_UP_FAILED: "SIGN_UP_FAILED",
	SIGN_UP_BUSINESS_STEP_COMPLETED: "SIGN_UP_BUSINESS_STEP_COMPLETED",
	FAILED_TO_UPDATE_BUSINESS_DETAILS: "FAILED_TO_UPDATE_BUSINESS_DETAILS",
	FAILED_IN_SENDING_OTP: "FAILED_IN_SENDING_OTP",
	RESEND_OTP: "RESEND_OTP",
	OTP_SENT: "OTP_SENT",
	OTP_ENTERED: "OTP_ENTERED",
	SIGN_UP_FORM_FILLED: "SIGN_UP_FORM_FILLED",
	SIGN_UP_LOGO_UPLOADED: "SIGN_UP_LOGO_UPLOADED",
	SIGN_UP_DOCUMENT_UPLOAD_STEP_COMPLETED: "SIGN_UP_DOCUMENT_UPLOAD_STEP_COMPLETED",
	SIGN_UP_DOCUMENT_UPLOAD_STEP_FAILED: "SIGN_UP_DOCUMENT_UPLOAD_STEP_FAILED",
	SIGN_UP_BUSINESS_REG_CERTIFICATE_UPDATED: "SIGN_UP_BUSINESS_REG_CERTIFICATE_UPDATED",
	SIGN_UP_GST_CERTIFICATE_UPDATED: "SIGN_UP_GST_CERTIFICATE_UPDATED",
	SIGN_UP_POC_STEP_COMPLETED: "SIGN_UP_POC_STEP_COMPLETED",
	SIGN_UP_POC_STEP_FAILED: "SIGN_UP_POC_STEP_FAILED",
	SIGN_UP_POC_STEP_SAME_AS_OWNER: "SIGN_UP_POC_STEP_SAME_AS_OWNER",
	SIGN_UP_POC_STEP_NOT_SAME_AS_OWNER: "SIGN_UP_POC_STEP_NOT_SAME_AS_OWNER",
	SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
	// Landing page events
	GOTO_DASHBOARD_FROM_LANDING_HEADER: "GOTO_DASHBOARD_FROM_LANDING_HEADER",
	SIGN_UP_FROM_LANDING_HEADER: "SIGN_UP_FROM_LANDING_HEADER",
	SIGN_IN_FROM_LANDING_HEADER: "SIGN_IN_FROM_LANDING_HEADER",
	SIGN_UP_FROM_LANDING_HERO_SECTION: "SIGN_UP_FROM_LANDING_HERO_SECTION",
	SIGN_UP_FROM_LANDING_FOOTER: "SIGN_UP_FROM_LANDING_FOOTER",
	LANDING_PAGE_SECTION_VIEW: "LANDING_PAGE_SECTION_VIEW",
	DEMO_FROM_LANDING_HERO_SECTION: "DEMO_FROM_LANDING_HERO_SECTION",
	DEMO_FROM_FOOTER_SECTION: "DEMO_FROM_FOOTER_SECTION",
	SENTRY_FEEDBACK_FORM_OPENED: "SENTRY_FEEDBACK_FORM_OPENED",
	SENTRY_FEEDBACK_FORM_SUBMITTED: "SENTRY_FEEDBACK_FORM_SUBMITTED",
	//KYC registration
	KYC_ACTIVATE_ACCOUNT_CLICK: "ACTIVATE_ACCOUNT_CLICK",
	KYC_RESUME_STEP_2_CLICK: "KYC_RESUME_STEP_2_CLICK",
	KYC_REJECTED_APPLY_AGAIN_CLICK: "KYC_REJECTED_APPLY_AGAIN_CLICK",
	KYC_STEP_1_COMPLETED: "KYC_STEP_1_COMPLETED",
	LOGO_UPLOAD: "LOGO_UPLOAD",
	KYC: {
		EMAIL_OTP_OPENED: "KYC/EMAIL_OTP_OPENED",
		EMAIL_OTP_ENTERING: "KYC/EMAIL_OTP_ENTERING",
		EMAIL_OTP_ENTERED: "KYC/EMAIL_OTP_ENTERED",
		EMAIL_OTP_SENT: "KYC/EMAIL_OTP_SENT",
		EMAIL_OTP_RESEND: "KYC/EMAIL_OTP_RESEND",
		REQUEST_RAISED: "KYC/REQUEST_RAISED"
	},
	// Visa Tracker table events
	VISA_TRACKER_TABLE_VISIBLE: "VISA_TRACKER_TABLE_VISIBLE",
	VISA_TRACKER_TABLE_APPLY_FOR_NEW_VISA: "VISA_TRACKER_TABLE_APPLY_FOR_NEW_VISA",
	VISA_TRACKER_TABLE_GO_TO_APPLICATION: "VISA_TRACKER_TABLE_GO_TO_APPLICATION",
	VISA_TRACKER_TABLE_GO_TO_PAY_NOW: "VISA_TRACKER_TABLE_GO_TO_PAY_NOW",
	VISA_TRACKER_TABLE_COUNTRY_FILTER_USED: "VISA_TRACKER_TABLE_COUNTRY_FILTER_USED",
	VISA_TRACKER_TABLE_DATES_FILTER_USED: "VISA_TRACKER_TABLE_DATES_FILTER_USED",
	// Apply for visa
	APPLICATION_APPLY_FOR_NEW_VISA: "APPLICATION_APPLY_FOR_NEW_VISA",
	APPLICATION_CONTINUE_LAST_APPLICATION: "APPLICATION_CONTINUE_LAST_APPLICATION",
	APPLICATION_ITINERARY_DETAILS_SUBMITTED: "APPLICATION_ITINERARY_DETAILS_SUBMITTED",
	APPLICATION_ADDED_PRIMARY_TRAVELLER: "APPLICATION_ADDED_PRIMARY_TRAVELLER",
	APPLICATION_ADDED_SECONDARY_TRAVELLER: "APPLICATION_ADDED_SECONDARY_TRAVELLER",
	APPLICATION_SELECTED_SEND_EMAIL_TO_PRIMARY: "APPLICATION_SELECTED_SEND_EMAIL_TO_PRIMARY",
	// Visa information tracking
	VISA_INFORMATION_COUNTRY_CHOSEN: "VISA_INFORMATION_COUNTRY_CHOSEN",
	VISA_INFORMATION_PURPOSE_CHOSEN: "VISA_INFORMATION_PURPOSE_CHOSEN",
	VISA_INFORMATION_DOCUMENTS_VIEWED: "VISA_INFORMATION_DOCUMENTS_VIEWED",
	VISA_INFORMATION_DOCUMENTS_LIST_SCROLL: "VISA_INFORMATION_DOCUMENTS_LIST_SCROLL",
	VISA_INFORMATION_SHARE_CLICKED: "VISA_INFORMATION_SHARE_CLICKED",
	VISA_INFORMATION_WA_QR_GENERATED_SHARE: "VISA_INFORMATION_WA_QR_GENERATED_SHARE",
	VISA_INFORMATION_WA_WEB_SHARE: "VISA_INFORMATION_WA_WEB_SHARE",
	VISA_INFORMATION_WA_TRAVELLER_NUMBER_ENTERED: "VISA_INFORMATION_WA_TRAVELLER_NUMBER_ENTERED",
	VISA_INFORMATION_WA_TAB_CLICKED: "VISA_INFORMATION_WA_TAB_CLICKED",
	VISA_INFORMATION_EMAIL_ENTERED: "VISA_INFORMATION_EMAIL_ENTERED",
	VISA_INFORMATION_EMAIL_SENT: "VISA_INFORMATION_EMAIL_SENT",
	VISA_INFORMATION_EMAIL_TAB_CLICKED: "VISA_INFORMATION_EMAIL_TAB_CLICKED",
	VISA_INFORMATION_VISA_TYPE_SELECTED: "VISA_INFORMATION_VISA_TYPE_SELECTED",
	// Order tracker
	ORDER_PREVIEW_CLICK: "ORDER_PREVIEW_CLICK",
	ORDER_CREATED: "ORDER_CREATED",
	DOC_UPLOAD_SKIP: "DOC_UPLOAD_SKIP",
	APPLICATION_ADDRESS_ADDED: "APPLICATION_ADDRESS_ADDED",
	VISA_FORM_LOADED: "VISA_FORM_LOADED",
	TRAVELLER_VISA_FORM_SUBMITTED: "TRAVELLER_VISA_FORM_SUBMITTED",
	ALL_TRAVELLERS_VISA_FORM_SUBMITTED: "ALL_TRAVELLERS_VISA_FORM_SUBMITTED",
	PHYSICAL_ERROR_CTA_CLICK: "PHYSICAL_ERROR_CTA_CLICK",
	FIX_DOCUMENT_CTA_CLICK: "FIX_DOCUMENT_CTA_CLICK",
	FIX_OFFLINE_DOCUMENT_CTA_CLICK: "FIX_OFFLINE_DOCUMENT_CTA_CLICK",
	FIX_VISA_FORM_CTA_CLICK: "FIX_VISA_FORM_CTA_CLICK",
	UPLOAD_ADDITIONAL_DOCUMENTS_CTA_CLICK: "UPLOAD_ADDITIONAL_DOCUMENTS_CTA_CLICK",
	ADD_TRAVELLER_CTA_CLICK: "ADD_TRAVELLER_CTA_CLICK",
	REVIEW_ACTION_CLICK: "REVIEW_ACTION_CLICK",
	REUPLOAD_DOC_CLICK: "REUPLOAD_DOC_CLICK",
	ORDER_CARD_GOTO_ORDER_CLICK: "ORDER_CARD_GOTO_ORDER_CLICK",
	ADD_TRAVELLER: "ADD_TRAVELLER",
	UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
	// Others
	DEMO_TOGGLE: "DEMO_TOGGLE",
	HELP_AND_SUPPORT_CLICK: "HELP_AND_SUPPORT_CLICK",
	PROFILE_CLICK: "PROFILE_CLICK",
	TNC_CLICK: "TNC_CLICK",
	LOGOUT_CLICK: "LOGOUT_CLICK",
	// Wallet
	WALLET_RECHARGE_OPEN: "WALLET_RECHARGE_OPEN",
	WALLET_AMOUNT_TOGGLE: "WALLET_AMOUNT_TOGGLE",
	WALLET_SIDEBAR_SELECT: "WALLET_SIDEBAR_SELECT",
	WALLET_COPY_UPI_HANDLE: "WALLET_COPY_UPI_HANDLE",
	WALLET_COPY_BANK_DETAILS: "WALLET_COPY_BANK_DETAILS",
	WALLET_INITIATE_PAYMENT_BY_CC: "WALLET_INITIATE_PAYMENT_BY_CC",
	WALLET_HISTORY_SEARCH: "WALLET_HISTORY_SEARCH",
	WALLET_HISTORY_FILTER: "WALLET_HISTORY_FILTER",
	ORDER_CREATION_JOURNEY: {
		COUNTRY_CHANGE: "ORDER_CREATION_JOURNEY/COUNTRY_CHANGE",
		PURPOSE_CHANGE: "ORDER_CREATION_JOURNEY/PURPOSE_CHANGE",
		TRAVEL_START_DATE_CHANGE: "ORDER_CREATION_JOURNEY/TRAVEL_START_DATE_CHANGE",
		TRAVEL_END_DATE_CHANGE: "ORDER_CREATION_JOURNEY/TRAVEL_END_DATE_CHANGE",
		TRAVELLERS_COUNT_CHANGE: "ORDER_CREATION_JOURNEY/TRAVELLERS_COUNT_CHANGE",
		CONTINUE_CLICK: "ORDER_CREATION_JOURNEY/CONTINUE_CLICK",
		SELECT_VISA_TYPE: "ORDER_CREATION_JOURNEY/SELECT_VISA_TYPE",
		COURIER_DOCS_CONTINUE_CLICK: "ORDER_CREATION_JOURNEY/COURIER_DOCS_CONTINUE_CLICK",
		ADDRESS_COPY: "ORDER_CREATION_JOURNEY/ADDRESS_COPY",
		APPLY_FOR_VISA_CLICK: "ORDER_CREATION_JOURNEY/APPLY_FOR_VISA_CLICK",
		ORDER_CREATED: "ORDER_CREATION_JOURNEY/ORDER_CREATED",
		START_DOCUMENTS_UPLOAD: "ORDER_CREATION_JOURNEY/START_DOCUMENTS_UPLOAD",
		DOCUMENTS_UPLOAD_SUCCESS: "ORDER_CREATION_JOURNEY/DOCUMENTS_UPLOAD_SUCCESS",
		SKIP_DOCUMENTS_UPLOAD: "ORDER_CREATION_JOURNEY/SKIP_DOCUMENTS_UPLOAD",
		VISA_FORM_ADD_TRAVELLER: "ORDER_CREATION_JOURNEY/VISA_FORM_ADD_TRAVELLER",
		VISA_FORM_SKIP: "ORDER_CREATION_JOURNEY/VISA_FORM_SKIP",
		VISA_FORM_SKIP_TRAVELLER: "ORDER_CREATION_JOURNEY/VISA_FORM_SKIP_TRAVELLER"
	},
	ORDER_TIMELINE_JOURNEY: {
		APPLICATION_OVERVIEW_CLICK: "ORDER_TIMELINE_JOURNEY/APPLICATION_OVERVIEW_CLICK",
		DOCUMENTS_OVERVIEW_CLICK: "ORDER_TIMELINE_JOURNEY/DOCUMENTS_OVERVIEW_CLICK",
		VISA_FORM_OVERVIEW_CLICK: "ORDER_TIMELINE_JOURNEY/VISA_FORM_OVERVIEW_CLICK",
		CANCEL_ORDER_CLICK: "ORDER_TIMELINE_JOURNEY/CANCEL_ORDER_CLICK",
		ORDER_CANCELLED: "ORDER_TIMELINE_JOURNEY/ORDER_CANCELLED",
		GET_VISAS_ON_EMAIL: "ORDER_TIMELINE_JOURNEY/GET_VISAS_ON_EMAIL",
		DOWNLOAD_VISAS: "ORDER_TIMELINE_JOURNEY/DOWNLOAD_VISAS",
		DOWNLOAD_INVOICE: "ORDER_TIMELINE_JOURNEY/DOWNLOAD_INVOICE",
		VIEW_INVOICE: "ORDER_TIMELINE_JOURNEY/VIEW_INVOICE",
		PAY_FOR_ORDER: "ORDER_TIMELINE_JOURNEY/PAY_FOR_ORDER",
		TABLE_OVERVIEW: {
			TRAVELLER_DROPDOWN_OPEN: "ORDER_TIMELINE_JOURNEY/TABLE_OVERVIEW/TRAVELLER_DROPDOWN_OPEN",
			TRAVELLER_EDIT_DRAWER_OPEN: "ORDER_TIMELINE_JOURNEY/TABLE_OVERVIEW/TRAVELLER_EDIT_DRAWER_OPEN",
			DELETE_TRAVELLER: "ORDER_TIMELINE_JOURNEY/TABLE_OVERVIEW/DELETE_TRAVELLER",
			ADDRESS_MODAL_OPEN: "ORDER_TIMELINE_JOURNEY/TABLE_OVERVIEW/ADDRESS_MODAL_OPEN",
			CLEAR_ALL_ADDRESS: "ORDER_TIMELINE_JOURNEY/TABLE_OVERVIEW/CLEAR_ALL_ADDRESS"
		}
	},
	MOBILE: {
		HOME_KYC_ACTIVATE_ACCOUNT_CLICK: "MOBILE/HOME_KYC_ACTIVATE_ACCOUNT_CLICK",
		HOME_ORDERS_LIST_CLICK: "MOBILE/HOME_ORDERS_LIST_CLICK",
		QUICK_ACTIONS_ORDERS_LIST_CLICK: "MOBILE/QUICK_ACTIONS_ORDERS_LIST_CLICK",
		QUICK_ACTIONS_WALLET_CLICK: "MOBILE/QUICK_ACTIONS_WALLET_CLICK",
		QUICK_ACTIONS_VISA_INFO_CLICK: "MOBILE/QUICK_ACTIONS_VISA_INFO_CLICK",
		QUICK_ACTIONS_VISA_INFO_COUNTRY_CLICK: "MOBILE/QUICK_ACTIONS_VISA_INFO_COUNTRY_CLICK",
		ORDER_LIST_ORDER_CLICK: "MOBILE/ORDER_LIST_ITEM_CLICK",
		ORDER_LIST_FILTER_CLICK: "MOBILE/ORDER_LIST_FILTER_CLICK",
		ORDER_DETAILS_INVOICE_CLICK: "MOBILE/ORDER_DETAILS_INVOICE_CLICK",
		ORDER_DETAILS_TIMELINE_PAYMENT_CLICK: "MOBILE/ORDER_DETAILS_TIMELINE_PAYMENT_CLICK",
		ORDER_DETAILS_TIMELINE_RECHARGE_WALLET_CLICK: "MOBILE/ORDER_DETAILS_TIMELINE_RECHARGE_WALLET_CLICK",
		VISA_INFO_COUNTRY_SELECT: "MOBILE/VISA_INFO_COUNTRY_SELECT",
		VISA_INFO_PURPOSE_SELECT: "MOBILE/VISA_INFO_PURPOSE_SELECT",
		VISA_INFO_VISA_TYPE_SELECT: "MOBILE/VISA_INFO_VISA_TYPE_SELECT",
		VISA_INFO_ITEM_CLICK: "MOBILE/VISA_INFO_ITEM_CLICK",
		WALLET_AMOUNT_TOGGLE: "MOBILE/WALLET_AMOUNT_TOGGLE",
		WALLET_RECHARGE_CLICK: "MOBILE/WALLET_RECHARGE_CLICK",
		WALLET_HISTORY_ITEM_CLICK: "MOBILE/WALLET_HISTORY_ITEM_CLICK",
		WALLET_RECHARGE_UPI_CLICK: "MOBILE/WALLET_RECHARGE_UPI_CLICK",
		WALLET_RECHARGE_CARD_CLICK: "MOBILE/WALLET_RECHARGE_CARD_CLICK",
		WALLET_RECHARGE_NEFT_CLICK: "MOBILE/WALLET_RECHARGE_NEFT_CLICK",
		WALLET_OPEN_UPI_APP_CLICK: "MOBILE/WALLET_OPEN_UPI_APP_CLICK",
		WALLET_UPI_ADDRESS_COPY: "MOBILE/WALLET_UPI_ADDRESS_COPY",
		WALLET_UPI_QR_CLICK: "MOBILE/WALLET_UPI_QR_CLICK"
	},
	DASHBOARD_SELECTED: "DASHBOARD_SELECTED"
};

export const MIXPANEL_PAGE_VIEWS_MAP: Record<string, string> = {
	"/": "LANDING_PAGE_VIEW",
	"/home": "HOME_PAGE_VIEW",
	"/reports": "REPORTS_PAGE_VIEW",
	"/auth/sign-up": "SIGN_UP_PAGE_VIEW",
	"/auth/sign-in": "SIGN_IN_PAGE_VIEW",
	"/order/details/:orderId": "TIMELINE_PAGE_VIEW",
	"/order/details/:orderId/documents": "DOCUMENTS_OVERVIEW_PAGE_VIEW",
	"/order/details/:orderId/visa-form/*": "VISA_FORM_PAGE_VIEW",
	"/order/list": "ORDER_LIST_PAGE_VIEW",
	"/documents-and-pricing": "DOCUMENTS_AND_PRICING_PAGE_VIEW",
	"/wallet": "WALLET_PAGE_VIEW"
};
