import { apiInstance } from "api/instance";

export const getMultiplePocInfo = async () => {
	return await apiInstance.get<ApiResponse<TUser[]>>("users/multiple-poc-info/");
};

export const deleteUser = async (delete_poc_id: string, assigned_user_id: string) => {
	return await apiInstance.delete<ApiResponse<TUser>>(`users/delete-multiple-poc/${delete_poc_id}`, {
		data: {
			assigned_user_id
		}
	});
};

export const addMultiplePoc = async (body: TUpsertUser) => {
	const response = await apiInstance.post<ApiResponse<TUser>>("users/add-multiple-poc/", body);
	return response.data.data;
};

export const updateMultiplePoc = async (body: TUpsertUser) => {
	const response = await apiInstance.patch<ApiResponse<TUser>>(`users/update-multiple-poc/${body._id}`, body);
	return response.data.data;
};

