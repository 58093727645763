import { Button, Form, Input } from "antd";
import toast from "components/common/Toaster";
import { useEffect, useState } from "react";
import { ROLES_MAP } from "screens/Users";
import { Modal } from "x-wings";
import { ROLES } from "../../schema";

interface UpsertUserModalProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (values: TUpsertUser) => Promise<void>;
	user?: TUpsertUser | null;
}

const UpsertUserModal: React.FC<UpsertUserModalProps> = ({ open, onClose, onSubmit, user }) => {
	const [form] = Form.useForm<TUpsertUser>();
	const [isPending, setIsPending] = useState(false);

	useEffect(() => {
		if (user) {
			form.setFieldsValue(user);
		} else {
			form.resetFields();
		}
	}, [user, form]);

	const handleFinish = async (values: TUpsertUser) => {
		setIsPending(true);
		try {
			await onSubmit(values);
			toast.success(user ? "User updated successfully" : "User added successfully");
			onClose();
		} catch (error: any) {
			if (error?.response?.data?.message) toast.error(error.response.data.message);
			else toast.error(user ? "Failed to update user" : "Failed to add user");
		} finally {
			setIsPending(false);
		}
	};

	return (
		<Modal
			title={user ? "Edit User" : "Add User"}
			open={open}
			afterOpenChange={(open) => {
				if (open) {
					const firstInput = document.querySelector("#upsert-form input") as HTMLInputElement;
					if (firstInput) {
						firstInput.focus();
					}
				}
			}}
			onCancel={onClose}
			footer={
				<div className="grid grid-cols-2 gap-3">
					<Button key="cancel" onClick={onClose} disabled={isPending}>
						Cancel
					</Button>
					<Button key="submit" type="primary" onClick={() => form.submit()} loading={isPending}>
						{user ? "Update" : "Add"}
					</Button>
				</div>
			}>
			<Form name="upsert-form" form={form} layout="vertical" onFinish={handleFinish} className="grid gap-3">
				<Form.Item
					name="firstName"
					label="First Name"
					rules={[{ required: true, message: "Please enter first name" }]}>
					<Input autoFocus />
				</Form.Item>
				<Form.Item
					name="lastName"
					label="Last Name"
					rules={[{ required: true, message: "Please enter last name" }]}>
					<Input />
				</Form.Item>
				<Form.Item name="role" label="Role" rules={[{ required: true, message: "Please select role" }]}>
					<select>
						<option disabled selected>
							Select role
						</option>
						{Object.keys(ROLES_MAP).map((role) => (
							<option key={role} value={role.toLowerCase()}>
								{ROLES_MAP[role as ROLES].label}
							</option>
						))}
					</select>
				</Form.Item>
				<Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter email" }]}>
					<Input type="email" />
				</Form.Item>
				<Form.Item
					name="phone"
					label="Phone"
					rules={[{ required: true, message: "Please enter phone number" }]}>
					<Input maxLength={10} />
				</Form.Item>
				<Form.Item name="_id" hidden>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default UpsertUserModal;

