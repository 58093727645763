import { useEffect, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { getMultiplePocInfo } from "../api";

const usePOCsInfo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { toggle, value } = useBoolean();
	const [data, setData] = useState<TUser[]>([]);

	useEffect(() => {
		const fetch = async () => {
			try {
				setIsLoading(true);
				const response = await getMultiplePocInfo();
				console.log("🚀 ~ fetch ~ response:", response);
				setData(response.data.data);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetch();
	}, [value]);

	return {
		isLoading,
		refetch: toggle,
		data
	};
};

export default usePOCsInfo;

