import { DotsThreeOutline, MagnifyingGlass, Pencil, Trash, UserCircle } from "@phosphor-icons/react";
import { Plus } from "@phosphor-icons/react/dist/ssr";
import { Button, Dropdown, Input, Table, TableProps, Tooltip } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { addMultiplePoc, updateMultiplePoc } from "./api";
import DeleteUserModal from "./components/DeleteUserModal";
import UpsertUserModal from "./components/UpsertUserModal";
import usePOCsInfo from "./hooks/usePOCsInfo";
import { ROLES } from "./schema";

export const ROLES_MAP: Record<
	TUser["roles"][number],
	{
		label: string;
		className: string;
	}
> = {
	SUPERVISOR: {
		label: "Owner",
		className: "text-blue-600"
	},
	TRAVEL_AGENT: {
		label: "Member",
		className: "text-orange-600"
	}
};

const Users = () => {
	const { isLoading, data, refetch } = usePOCsInfo();

	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [activeUser, setActiveUser] = useState<RecordType | null>(null);
	const [showUpsertModal, setShowUpsertModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	type RecordType = (typeof data)[number];

	const deleteUserClick = (record: RecordType) => {
		setActiveUser(record);
		setShowDeleteModal(true);
	};

	const handleAddUser = () => {
		setActiveUser(null);
		setShowUpsertModal(true);
	};

	const handleEditUser = (record: RecordType) => {
		setActiveUser(record);
		setShowUpsertModal(true);
	};

	const handleUpsertUser = async (values: TUpsertUser) => {
		if (activeUser) {
			await updateMultiplePoc(values);
		} else {
			await addMultiplePoc(values);
		}
		refetch();
	};

	const filteredData = data.filter((user) => {
		const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
		const email = user.email?.toLowerCase() ?? "";
		const role = ROLES_MAP[user.roles[0]].label.toLowerCase();
		return (
			fullName.includes(searchTerm.toLowerCase()) ||
			email.includes(searchTerm.toLowerCase()) ||
			role.includes(searchTerm.toLowerCase())
		);
	});

	const columns: TableProps<RecordType>["columns"] = [
		{
			title: "Name",
			key: "name",
			render: (record: RecordType) => (
				<div className="flex items-center gap-2">
					<UserCircle className={clsx(ROLES_MAP[record.roles[0]].className)} size={20} />
					{`${record.first_name} ${record.last_name}`}
				</div>
			)
		},
		{
			title: "Role",
			dataIndex: "roles",
			render: (roles: RecordType["roles"]) => (
				<div className="">
					{roles.map((role) => (
						<span
							key={role}
							className={clsx("bg-current/50 rounded-md px-2 py-1", ROLES_MAP[role].className)}>
							{ROLES_MAP[role].label}
						</span>
					))}
				</div>
			)
		},
		{
			title: "Contact No.",
			dataIndex: "phone",
			render: (phone: string) => <span className="tabular-nums">{phone.replace(/(\d{5})(\d+)/, "$1 $2")}</span>
		},
		{
			title: "E-Mail",
			dataIndex: "email",
			className: "text-gray-500"
		},
		{
			title: "Date Added",
			dataIndex: "created_at",
			width: 160,
			sorter: (a: RecordType, b: RecordType) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
			render: (date: string, record: RecordType) => (
				<div className="flex h-5 items-center justify-between gap-3">
					<Tooltip title={dayjs(date).format("DD MMM YYYY hh:mm A")}>
						<span className="tabular-nums">
							<span>{dayjs(date).format("DD MMM YYYY")}</span>
						</span>
					</Tooltip>

					<Dropdown
						menu={{
							className: "w-40 !ring-1 !ring-gray-200",
							items: [
								{
									key: "title",
									label: "User Options",
									className: "!font-medium !text-gray-700 hover:!bg-transparent"
								},
								{
									type: "divider"
								},
								{
									key: "edit",
									label: "Edit",
									icon: <Pencil weight="bold" size={14} />,
									onClick: () => {
										handleEditUser(record);
									}
								},
								{
									key: "delete",
									label: "Delete",
									disabled: record.roles.includes(ROLES.SUPERVISOR) && data.length === 1,
									icon: <Trash weight="bold" size={14} className="text-red-600" />,
									className: "hover:!bg-red-100 !text-red-700",
									onClick: () => {
										deleteUserClick(record);
									}
								}
							]
						}}>
						<div
							role="button"
							className={clsx(
								"grid size-6 place-items-center rounded-md border-2 border-solid border-white bg-gray-200 ring-2 ring-gray-200",
								{ hidden: hoveredRow !== record._id }
							)}>
							<DotsThreeOutline size={14} weight="fill" />
						</div>
					</Dropdown>
				</div>
			)
		}
	];

	return (
		<main className="mx-6">
			<div>
				<div className="flex flex-col gap-1.5 pt-5">
					<div className="flex items-center justify-between font-['Inter'] text-2xl font-semibold  text-black">
						Users
					</div>

					<p className="w-[1003px] font-['Inter'] text-[13px] font-normal leading-[18px] text-gray-700">
						Add and manage users, their roles and information{" "}
					</p>
				</div>
			</div>

			<div className="mt-6">
				<div className="flex items-center justify-between gap-3">
					<div>
						<Input
							placeholder="Search by user, role, email..."
							className="w-60 flex-1 !bg-gray-50"
							onChange={(e) => setSearchTerm(e.target.value)}
							prefix={<MagnifyingGlass size={15} weight="bold" className="text-gray-400" />}
						/>
					</div>
					<div>
						<Button type="primary" icon={<Plus />} onClick={handleAddUser}>
							Add new member
						</Button>
					</div>
				</div>
			</div>

			<div className="mt-4">
				<Table
					columns={columns}
					dataSource={filteredData}
					loading={isLoading}
					pagination={false}
					bordered={false}
					rowKey="_id"
					onRow={(_, index) => ({
						onMouseEnter: () => setHoveredRow(_._id),
						onMouseLeave: () => setHoveredRow(null)
					})}
				/>
			</div>
			<DeleteUserModal
				open={showDeleteModal}
				onClose={() => {
					refetch();
					setShowDeleteModal(false);
				}}
				user={activeUser}
				users={data}
			/>
			<UpsertUserModal
				open={showUpsertModal}
				onClose={() => setShowUpsertModal(false)}
				onSubmit={handleUpsertUser}
				user={{
					_id: activeUser?._id,
					firstName: activeUser?.first_name ?? "",
					middleName: activeUser?.middle_name,
					lastName: activeUser?.last_name ?? "",
					role: activeUser?.roles[0].toLowerCase() as ROLES,
					email: activeUser?.email,
					phone: activeUser?.phone ?? ""
				}}
			/>
		</main>
	);
};

export default Users;

