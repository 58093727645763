import NewTnCAgreementModal from "components/NewTnCAgreementModal";
import TransitionedFromRoamyModal from "components/TransitionedFromRoamyModal";
import useIsMobile from "hooks/useIsMobile";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { useBoolean, When } from "x-wings";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

const MainLayout: React.FC = () => {
	const { user, appConfig, patchAppConfig } = useAuthContext();
	const location = useLocation();

	const isMobile = useIsMobile();
	const {
		value: openNewTnCAgreementModal,
		setTrue: onOpenNewTnCAgreementModal,
		setFalse: onCloseNewTnCAgreementModal
	} = useBoolean();
	const {
		value: openTransitionedFromRoamyModal,
		setTrue: onOpenTransitionedFromRoamyModal,
		setFalse: onCloseTransitionedFromRoamyModal
	} = useBoolean();

	useEffect(() => {
		if (!appConfig) return;
		if (appConfig.metadata.isNewTnCApproved) return;
		if (user?.organisation_id.status !== "APPROVED") return;
		onOpenNewTnCAgreementModal();
	}, [appConfig, user, onOpenNewTnCAgreementModal]);

	useEffect(() => {
		if (!appConfig) return;
		if (appConfig.metadata.isTransitionedFromRoamy) return;
		if (user?.organisation_id.status !== "APPROVED") return;
		onOpenTransitionedFromRoamyModal();
	}, [appConfig, user, onOpenTransitionedFromRoamyModal]);

	if (!user) {
		const redirect = encodeURIComponent(location.pathname + location.search).replace(/&/g, "%26");
		return <Navigate to={`/auth/sign-in?redirect=${redirect}`} replace />;
	}

	return (
		<>
			<When isTrue={isMobile}>
				<MobileLayout />
			</When>
			<When isTrue={!isMobile}>
				<DesktopLayout />
			</When>
			<NewTnCAgreementModal
				open={openNewTnCAgreementModal}
				onAgree={() => {
					patchAppConfig({ isNewTnCApproved: true }).then(() => {
						onCloseNewTnCAgreementModal();
					});
				}}
			/>
			<TransitionedFromRoamyModal
				open={openTransitionedFromRoamyModal}
				onClose={() => {
					patchAppConfig({ isTransitionedFromRoamy: true }).then(() => {
						onCloseTransitionedFromRoamyModal();
					});
				}}
			/>
		</>
	);
};

export default MainLayout;
