import { ArrowRight, X } from "@phosphor-icons/react";
import { Button, Form, Modal } from "antd";
import toast from "components/common/Toaster";
import { useState } from "react";
import { deleteUser } from "screens/Users/api";

const DeleteUserModal = ({
	open,
	onClose,
	user,
	users
}: {
	open: boolean;
	onClose: () => void;
	user: Record<string, any> | null;
	users: Record<string, any>[];
}) => {
	const [isPending, setIsPending] = useState(false);

	const handleDelete = async (values: { reassign: string }) => {
		if (!user) return;
		setIsPending(true);
		try {
			await deleteUser(user._id, values.reassign);
			toast.success(`User ${user.first_name} ${user.last_name} has been deleted successfully`);
			onClose();
		} catch (err: any) {
			if (err?.response?.data?.message) toast.error(err.response.data.message);
			else toast.error("Failed to delete user");
		} finally {
			setIsPending(false);
		}
	};

	return (
		<Modal
			className="[&_.ant-modal-content]:overflow-clip [&_.ant-modal-content]:rounded-lg [&_.ant-modal-content]:p-0 [&_.ant-modal-header]:mb-0"
			closeIcon={null}
			footer={null}
			onCancel={onClose}
			destroyOnClose
			title={
				<div className="flex h-12 items-center justify-between border-b border-red-200 bg-white px-4 py-2">
					<div className="font-['Inter'] text-base font-medium leading-normal text-red-700">
						Delete member
					</div>
					<div className="flex items-center justify-start gap-2">
						<div className="inline-flex h-4 flex-col items-center justify-center overflow-hidden rounded border border-white bg-gray-100 px-1 shadow shadow-[0px_0px_0px_1px_rgba(17,24,39,0.10)] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)]">
							<div className="text-center font-['Inter'] text-xs font-medium leading-none text-gray-600">
								esc
							</div>
						</div>
						<X role="button" onClick={onClose} />
					</div>
				</div>
			}
			open={open}
			onClose={onClose}>
			<div className="grid">
				<div className="flex h-48 flex-col items-center justify-start gap-4 bg-gradient-to-b from-red-100 to-white px-4 pb-6 pt-4">
					<div data-svg-wrapper className="relative">
						<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M32 6C26.8577 6 21.8309 7.52487 17.5552 10.3818C13.2795 13.2387 9.94702 17.2994 7.97914 22.0502C6.01127 26.8011 5.49638 32.0288 6.49959 37.0723C7.50281 42.1159 9.97907 46.7486 13.6152 50.3848C17.2514 54.0209 21.8842 56.4972 26.9277 57.5004C31.9712 58.5036 37.1989 57.9887 41.9498 56.0209C46.7007 54.053 50.7613 50.7205 53.6182 46.4448C56.4751 42.1691 58 37.1423 58 32C57.9927 25.1066 55.2511 18.4976 50.3767 13.6233C45.5024 8.74889 38.8934 6.00728 32 6ZM30 20C30 19.4696 30.2107 18.9609 30.5858 18.5858C30.9609 18.2107 31.4696 18 32 18C32.5304 18 33.0392 18.2107 33.4142 18.5858C33.7893 18.9609 34 19.4696 34 20V34C34 34.5304 33.7893 35.0391 33.4142 35.4142C33.0392 35.7893 32.5304 36 32 36C31.4696 36 30.9609 35.7893 30.5858 35.4142C30.2107 35.0391 30 34.5304 30 34V20ZM32 46C31.4067 46 30.8266 45.8241 30.3333 45.4944C29.84 45.1648 29.4554 44.6962 29.2284 44.148C29.0013 43.5999 28.9419 42.9967 29.0577 42.4147C29.1734 41.8328 29.4591 41.2982 29.8787 40.8787C30.2982 40.4591 30.8328 40.1734 31.4147 40.0576C31.9967 39.9419 32.5999 40.0013 33.1481 40.2284C33.6962 40.4554 34.1648 40.8399 34.4944 41.3333C34.8241 41.8266 35 42.4067 35 43C35 43.7957 34.6839 44.5587 34.1213 45.1213C33.5587 45.6839 32.7957 46 32 46Z"
								fill="#DC2626"
							/>
							<path
								d="M32 6C26.8577 6 21.8309 7.52487 17.5552 10.3818C13.2795 13.2387 9.94702 17.2994 7.97914 22.0502C6.01127 26.8011 5.49638 32.0288 6.49959 37.0723C7.50281 42.1159 9.97907 46.7486 13.6152 50.3848C17.2514 54.0209 21.8842 56.4972 26.9277 57.5004C31.9712 58.5036 37.1989 57.9887 41.9498 56.0209C46.7007 54.053 50.7613 50.7205 53.6182 46.4448C56.4751 42.1691 58 37.1423 58 32C57.9927 25.1066 55.2511 18.4976 50.3767 13.6233C45.5024 8.74889 38.8934 6.00728 32 6ZM30 20C30 19.4696 30.2107 18.9609 30.5858 18.5858C30.9609 18.2107 31.4696 18 32 18C32.5304 18 33.0392 18.2107 33.4142 18.5858C33.7893 18.9609 34 19.4696 34 20V34C34 34.5304 33.7893 35.0391 33.4142 35.4142C33.0392 35.7893 32.5304 36 32 36C31.4696 36 30.9609 35.7893 30.5858 35.4142C30.2107 35.0391 30 34.5304 30 34V20ZM32 46C31.4067 46 30.8266 45.8241 30.3333 45.4944C29.84 45.1648 29.4554 44.6962 29.2284 44.148C29.0013 43.5999 28.9419 42.9967 29.0577 42.4147C29.1734 41.8328 29.4591 41.2982 29.8787 40.8787C30.2982 40.4591 30.8328 40.1734 31.4147 40.0576C31.9967 39.9419 32.5999 40.0013 33.1481 40.2284C33.6962 40.4554 34.1648 40.8399 34.4944 41.3333C34.8241 41.8266 35 42.4067 35 43C35 43.7957 34.6839 44.5587 34.1213 45.1213C33.5587 45.6839 32.7957 46 32 46Z"
								fill="url(#paint0_linear_19_1353)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_19_1353"
									x1="32"
									y1="6"
									x2="32"
									y2="58"
									gradientUnits="userSpaceOnUse">
									<stop stopColor="white" stopOpacity="0.2" />
									<stop offset="1" stopColor="white" stopOpacity="0" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="flex h-20 flex-col items-start justify-start gap-1 self-stretch">
						<div className="self-stretch text-center font-['Inter'] text-xl font-medium leading-7 text-red-700">
							You are removing a member from your team!
						</div>
						<div className="self-stretch text-center font-['Inter'] text-base font-normal leading-normal text-neutral-700">
							Are you sure you want to delete this member? Re-assign non-completed orders to someone.
						</div>
					</div>
				</div>
				<div className="px-4">
					<div className="h-px bg-neutral-300" />
				</div>
				<Form layout="vertical" className="mt-4 space-y-4" onFinish={handleDelete}>
					<div className="px-4">
						<Form.Item
							label={`Re-assign ${user?.first_name}’s orders to`}
							required
							name="reassign"
							rules={[{ required: true, message: "Please select a user to re-assign orders" }]}
							className="grid ">
							<select autoFocus>
								<option disabled selected>
									Select user
								</option>
								{users
									.filter((u) => u._id !== user?._id)
									.map((u) => (
										<option key={u._id} value={u._id}>
											{u.first_name} {u.last_name}
										</option>
									))}
							</select>
						</Form.Item>
					</div>

					<div className="grid h-16 grid-cols-3 items-center justify-start gap-3 bg-neutral-100 p-4">
						<Button onClick={onClose} className="!text-gray-600">
							Cancel
						</Button>
						<Button
							loading={isPending}
							htmlType="submit"
							type="primary"
							className="!hover:bg-red-700 col-span-2 !bg-red-600 !ring-red-500">
							Delete member and re-assign orders
							<ArrowRight size={20} />
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	);
};

export default DeleteUserModal;

