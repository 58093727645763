import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";

const PublicRouteWrapper = () => {
	const { user } = useAuthContext();

	const [searchParams] = useSearchParams();

	const redirect = searchParams.get("redirect") || "/home";

	return user ? <Navigate to={redirect} replace /> : <Outlet />;
};

export default PublicRouteWrapper;
