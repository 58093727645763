import { z } from "zod";

export enum ROLES {
	SUPERVISOR = "SUPERVISOR",
	TRAVEL_AGENT = "TRAVEL_AGENT"
}

export const UserSchema = z.object({
	reports_to: z.array(z.any()),
	is_supervisor_role_added: z.boolean(),
	_id: z.string(),
	organisation_id: z.string(),
	phone: z.string(),
	first_name: z.string(),
	last_name: z.string(),
	roles: z.array(z.nativeEnum(ROLES)),
	type: z.string(),
	is_email_verified: z.boolean(),
	is_active: z.boolean(),
	is_deleted: z.boolean(),
	is_smv_user: z.boolean(),
	created_at: z.coerce.date(),
	updated_at: z.coerce.date(),
	frontend_app_version_key: z.string(),
	email: z.string().optional(),
	is_whatsapp_opt_in: z.boolean(),
	middle_name: z.string().optional(),
	created_by: z.string().optional()
});

export const UpsertUserSchema = z.object({
	_id: z.string().optional(),
	firstName: z.string(),
	middleName: z.string().optional(),
	lastName: z.string(),
	role: z.nativeEnum(ROLES),
	email: z.string().optional(),
	phone: z.string()
});

